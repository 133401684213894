import * as React from "react";
import Layout from "../components/layout";

// markup
const NotFoundPage = () => {
  return (
    <Layout
      titleText="Not Found"
      titleText2=""
      slogan="Sorry the page you are looking for cannot be found"
    ></Layout>
  );
};

export default NotFoundPage;
